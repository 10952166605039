module.exports={
  "$id": "network-object.spec.json",
  "$schema": "http://json-schema.org/schema#",
  "title": "Network Object",

  "type": "object",
  "properties": {
    "address": { "$ref": "#/definitions/Address" },
    "transactionHash": { "$ref": "#/definitions/TransactionHash" },
    "events": {
      "type": "object",
      "patternProperties": {
        "^0x[a-fA-F0-9]{64}$": { "$ref": "abi.spec.json#/definitions/Event" }
      },
      "additionalProperties": false
    },
    "links": {
      "type": "object",
      "patternProperties": {
        "^[a-zA-Z_][a-zA-Z0-9_]*$": { "$ref": "#/definitions/Address" }
      },
      "additionalProperties": false
    },
    "db": {
      "type": "object",
      "patternProperties": {
        "^[a-zA-Z0-9]+$": {
          "type": "object",
          "description": "Reference to @truffle/db canonical ID object for correlation purposes",
          "properties": {
            "id": {
              "type": "string"
            }
          }
        }
      }
    }
  },
  "additionalProperties": false,

  "definitions": {
    "Address": {
      "type": "string",
      "pattern": "^0x[a-fA-F0-9]{40}$"
    },

    "TransactionHash": {
      "type": "string",
      "pattern": "^0x[a-fA-F0-9]{64}$"
    }
  }
}
