module.exports={
  "name": "@truffle/contract",
  "description": "A better contract abstraction for Ethereum (formerly EtherPudding)",
  "license": "MIT",
  "author": "Tim Coulter <tim@trufflesuite.com>",
  "homepage": "https://github.com/trufflesuite/truffle/tree/master/packages/contract#readme",
  "repository": {
    "type": "git",
    "url": "https://github.com/trufflesuite/truffle.git",
    "directory": "packages/contract"
  },
  "bugs": {
    "url": "https://github.com/trufflesuite/truffle/issues"
  },
  "version": "4.3.25",
  "main": "index.js",
  "scripts": {
    "compile": "sh -c \"mkdir -p ./dist\" && browserify --debug ./index.js | exorcist ./dist/truffle-contract.js.map > ./dist/truffle-contract.js && uglifyjs ./dist/truffle-contract.js -o ./dist/truffle-contract.min.js",
    "prepare": "yarn compile",
    "publish:next": "node ../truffle/scripts/prereleaseVersion.js next next"
  },
  "typings": "./typings/index.d.ts",
  "dependencies": {
    "@ensdomains/ensjs": "^2.0.1",
    "@truffle/blockchain-utils": "^0.0.31",
    "@truffle/contract-schema": "^3.4.1",
    "@truffle/debug-utils": "^5.1.5",
    "@truffle/error": "^0.0.14",
    "@truffle/interface-adapter": "^0.5.2",
    "bignumber.js": "^7.2.1",
    "ethers": "^4.0.32",
    "web3": "1.4.0",
    "web3-core-helpers": "1.4.0",
    "web3-core-promievent": "1.4.0",
    "web3-eth-abi": "1.4.0",
    "web3-utils": "1.4.0"
  },
  "devDependencies": {
    "browserify": "^17.0.0",
    "debug": "^4.3.1",
    "exorcist": "^2.0.0",
    "uglify-es": "^3.3.9"
  },
  "keywords": [
    "abstraction",
    "contract",
    "ethereum",
    "truffle"
  ],
  "publishConfig": {
    "access": "public"
  },
  "gitHead": "ee43a9a8391547a1e607aa944ada006a41bcf5c7"
}
